/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2023-11-04",
    versionChannel: "nightly",
    buildDate: "2023-11-04T00:14:08.342Z",
    commitHash: "4403848a4b496b2a21bc9b76481e442d59a0595d",
};
